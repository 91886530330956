<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="justify-content-center col-md-12">
      <CCard
        color="white"
        text-color="dark"
        class="text-center col-md-6"
        body-wrapper
      >
        <CAlert v-if="alert != ''" color="danger" id="alert">{{ alert }}</CAlert>
        <CAlert v-if="success != ''" color="success" id="success">{{ success }}</CAlert>
        <img src="/SilomPOS_logo.png" style="width:60%" id="logo"/>
        <CForm id="form">
          <br />
          <CInput
            v-model="email"
            type="email"
            :placeholder="$t('enterEmail')"
            autocomplete="username email"
            id="email-input"
          >
            <template #prepend-content>
              <CIcon name="cil-envelope-closed" id="email-icon"/>
            </template>
          </CInput>

          <CRow>
            <CCol col="12">
              <CButton
                id="resetPasswordWithEmail"
                @click="resetPasswordWithEmail"
                block
                color="success"
                class="px-4"
                >{{ $t('requirenewpass') }}</CButton
              >
            </CCol>
          </CRow>
          <hr />
          <CCol col="12" class="text-center">
            <CButton id="signIn" block color="white" class="px-0" to="/login"
              >{{ $t('signIn') }}</CButton
            >
          </CCol>
        </CForm>
      </CCard>
    </CRow>
  </CContainer>
</template>

<script>
import firebase from 'firebase/app'

export default {
  name: 'Login',
  data() {
    return {
      isBusy: true,
      email: '',
      alert: '',
      success: '',
    }
  },
  methods: {
    resetPasswordWithEmail() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.alert = ''
          this.success = this.$t('checkEmail')
        })
        .catch((error) => {
          this.alert = this.$t('wrongEmail')
          this.success = ''
          console.log(error)
        })
    },
  },
}
</script>
